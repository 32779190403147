import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import {SlideshowLightbox} from 'lightbox.js-react'
import Footer from "../component/Footer";
import Header from "../component/Header";
const SubFest = () => {
        
    const navigate = useNavigate();
    const location = useLocation();
    const id = new URLSearchParams(location.search).get("id");
    const [data, setData] = useState("");
    const [index, setIndex] = React.useState(-1);
  
    console.log(id);
  
    useEffect(() => {
      const getData = async () => {
        try {
          let subgalleryData = await getSubGallery(id);
          console.log(subgalleryData);
          setData(subgalleryData);
        } catch (error) {
          console.log(error);
        }
      };
      getData();
    }, []);
  
    console.log(data);
  
    const slides = data?.attachments?.map(img => ({
      src: `https://webapi.entab.info/api/image/${img}`
    }));
  return (
    <>
             <Header />
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <h2>Events at SGC </h2>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>|</li>
                  <li>Events at SGC </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section">
    <div className="container">
       <div className="row">
          <div className="col-md-12">    
             <div className="fulwidth">
                <div className="sub-gallery">
                   <div className="shrink"><Link to="/"><i className="bi bi-arrow-left"></i></Link></div>
                   <div><b>Fest Date:</b> {" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>
                   <div><b>Total Photos:</b> {data?.attachments?.length}</div>
                </div>
                <div className="discription">
                   <h3 className="subhead">{data.title}</h3>
                   <p><b>Description:</b>{data.description}</p>
                </div> 
             </div>
             <div className="flexbox-v zoompic">
             {data !== "" &&
              data?.attachments.map((img,index) => (
                <div>
                       <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`}>
                        <img src={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}  alt="St. George’s College"  className="img-fluid"/>
                        </a>
                </div>
                ))} 
                {/* <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div>
                <div>
                     <SlideshowLightbox><img src="https://webapi.entab.info/api/image/SGSM/public/Images/Images/gal-1.jpg" alt="Loreto Convent School Delhi Cantt" className="img-fluid"/></SlideshowLightbox>
                </div> */}
             </div> 
          </div>
       </div>
    </div>
    </div>
    <Footer />
   </>
  )
}

export default SubFest