import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import { Link } from 'react-router-dom';
import Footer from "../component/Footer";
import Header from "../component/Header";

const TopperX = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("");
   const [yearRanges, setYearRanges] = useState([]);   
   const [searchQuery, setSearchQuery] = useState("");

   useEffect(() => {
      const currentYear = new Date().getFullYear();
      const Year = `${currentYear-1}-${currentYear}`;
      setSelectedYear(Year);
      console.log("get current Year", Year);
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         const galleryData1 = await getTopper();
         const galleryData = galleryData1.filter((item) => item.class === 'X'); 
         setData(galleryData);  

         // Extract unique years
         const uniqueYears = Array.from(
            new Set(galleryData.map((item) => item.sessionYear))
         );

         console.log("Unique Years:", uniqueYears); 
         setYearRanges(uniqueYears);
         setFilteredData(galleryData); // Initialize filteredData with all data
      };
      fetchData();
   }, []);

   useEffect(() => {
      // Filter data based on selected year and search query
      let filteredData = data;
      if (selectedYear !== "All") {
         filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
      }
      if (searchQuery) {
         filteredData = filteredData.filter((item) =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
         );
      }

      // Sort data by division in descending order
      filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));

      setFilteredData(filteredData);
   }, [selectedYear, searchQuery, data]);

   return (
      <>
            <Header />
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <h2>Topper Of X </h2>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>|</li>
                  <li>Topper Of X </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section">
            <div className="container">
               <div className="row tabs-dynamic">
                  <div className="col-md-6">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection"
                        >
                           <option value={"All"}>All</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
               <div className="row  years year2023 showYear">
                  {filterData.length > 0 ?(
                     filterData.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="topper-section  text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                    <img className="rounded-circle" src={`https://webapi.entab.info/api/image/${item.attachments}`} alt="St. George's College" />
                                 </div>
                                 <div className="details clearfix">
                                    <h4 className="title1">  {item?.name} </h4>
                                    <p>{item?.stream}</p>
                                    <p><span className="font-weight-800 text-theme-colored3">Marks:</span> {item?.division}</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))): (
                        <div className="col-md-3" >
                           <div className="topper-section  text-center mb-lg-50 innertopper">
                              <div className="topper-service">
                                 <div className="thumb">
                                    <img className="rounded-circle" src="https://webapi.entab.info/api/image/SGSM/public/Images/user.png" alt="St. George's College" />
                                 </div>
                                 <div className="details clearfix">
                                    <h4 className="title1">  Student Name </h4>
                                    <p>Stream</p>
                                    <p><span className="font-weight-800 text-theme-colored3">Marks:</span> %</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};

export default TopperX;
