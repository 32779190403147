import React, { useEffect } from "react";
import Slider from "react-slick";

const Events = ({ data, NewsData }) => {
    useEffect(() => {
        console.log(NewsData);
    }, [NewsData]);

    const combinedData = [...data, ...NewsData];
    const isSingleSlide = combinedData.length === 1;

    var settings = {
        dots: false,
        infinite: !isSingleSlide,  // Disable infinite scroll if only one slide
        speed: 500,
        slidesToShow: isSingleSlide ? 1 : 2, // Show only one slide if there's only one
        slidesToScroll: 1,
        autoplay: !isSingleSlide,  // Disable autoplay for a single slide
        initialSlide: 0,
        centerMode: isSingleSlide, // Center the slide if there's only one
        centerPadding: isSingleSlide ? "20%" : "0", // Apply padding for centering
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: isSingleSlide ? 1 : 2,
                    slidesToScroll: 1,
                    centerMode: isSingleSlide, // Keep centering for smaller screens
                    centerPadding: isSingleSlide ? "20%" : "0", 
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    centerMode: isSingleSlide, // Center for mobile as well
                    centerPadding: isSingleSlide ? "10%" : "0",
                }
            }
        ]
    };

    return (
        <div>
            <Slider {...settings}>
                {data.map((item, index) => (
                    <div key={index}>
                        <div className='evt-blk'>
                            <div className="evt-img">
                                {item.images && item.images.length > 0 ? (
                                    <img src={`https://webapi.entab.info/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. George College Mussoorie" />
                                ) : (
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about.jpg" className="img-fluid mx-auto d-block" alt="St. George College Mussoorie" />
                                )}
                                <p className='category'>Events</p>
                            </div>
                            <div className='evt-content'>
                                <div className="evt-title"><h3>{item.title}</h3></div>
                                <div className="evt-dtl"><i className="bi bi-geo-alt-fill"></i> {item.venue} <div className="date"><i className="bi bi-calendar3"></i> {item.date}</div></div>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
                
                {NewsData.map((item, index) => (
                    <div key={index}>
                        <div className='evt-blk'>
                            <div className="evt-img">
                                {item.images && item.images.length > 0 ? (
                                    <img src={`https://webapi.entab.info/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St. George College Mussoorie" />
                                ) : (
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about.jpg" className="img-fluid mx-auto d-block" alt="St. George College Mussoorie" />
                                )}
                                <p className='category'>News</p>
                            </div>
                            <div className='evt-content'>
                                <div className="evt-title"><h3>{item.title}</h3></div>
                                <div className="evt-dtl"><div className="date" style={{ float: 'unset', marginLeft: 'auto', display: 'block', textAlign: 'right' }}><i className="bi bi-calendar3"></i> {item.fromDate}</div></div>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Events;
