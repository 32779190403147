import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const About = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>About School</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 className="inner-main-title"><span className="dark-bg">ABOUT</span> <span className="light-bg">Us</span></h3>
                        </div>
                        <div className='col-md-6'>
                            <div className='about-img'>
                                <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about.jpg" className='img-fluid about-img1' alt="St. George college" />
                                <img src="https://webapi.entab.info/api/image/SGSM/public/Images/about2.jpg" className='img-fluid about-img2' alt="St. George college" />
                            </div>
                        </div>
                        <div className='col-md-6'>
                       
                        <p>Situated in Barlowganj, a sleepy village nestling in the foothills of the Himalayas, St.George’s College was established in the <span>year 1853,</span> by the Brothers of the Patrician Congregation. Manor House, as the college is more popularly known , began in an attempt to provide quality education to British Catholic children, on the lines of the  Public School System in Britain. Affiliated to the Council for Indian <span>School Certificate Examinations (CISCE), </span>St. George’s College is a residential cum day school for students of classes  four to twelve. </p>
                        <p>Generations of young men have graduated from the portals of this venerable institution, imbued with the cherished qualities of undiminished  fortitude, honour, and resilience. Distinguished among these, is a long list of celebrated sportsmen, religious leaders, educationists, statesmen and bureaucrats, stalwarts of the Defence Forces, actors and musicians.   </p>
                        <p>With the mission of promoting  lifelong learning in an open and caring atmosphere that motivates students to be confident and responsible global citizens , Manor House strives to create a home away from home where universal brotherhood is imbibed and nurtured for a lifetime along with the desire to contribute positively through selfless service, putting the totality of God-given talents and acquired knowledge to the service of humanity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About