import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const About = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Our Values</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Our Values</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 className="inner-main-title"><span className="dark-bg">Our</span> <span className="light-bg">Values</span></h3>
                        </div> 
                        <div className='col-md-12'>
                       
                        <p>At Manor House, we endeavor to instill qualities of judgment, self-reliance, integrity and self-discipline in our students. SGC attaches great emphasis on self discipline and a responsible exhibition of accepted moral character. Our overall Code of Conduct is based on the need to ensure the security of the students as well as consideration for others and acceptable standards of behavior.</p>
                        <p>We believe in educating the whole person so that every student leaves the school well-equipped to achieve individual goals and with a sense of commitment, social responsibility and enthusiasm.</p>
                        <p>Our students are encouraged to think creatively and independently. The spirit of healthy competition and cooperation is encouraged through sports and other activities. Students learn about leadership and teamwork - the glory of winning and the importance of losing gracefully, yet with the determination to do better next time.</p>
                        <p class="inner-title"> <span>OUR GRAND VISION OF A MANORITE</span></p> 
                        <p><b>Young men who will:</b></p>
                        <ul className='list-view'>
                            <li>* See the entire creation worthy of reverence</li>
                            <li>* Face the vicissitudes of life with confidence and courage</li>
                            <li>* Have a global outlook</li>
                            <li>* Be a constructive, patriotic and contributing citizen of his country</li>
                            <li>* Be a productive and responsible member of the human family</li>
                            <li>* Respect and cherish the cultural, ethical and social values</li>
                            <li>* Be rooted in eternal values</li>
                            <li>To sum it up, a Manorite should be a worthy and ideal individual of the society...<br></br>
                            <b>Transcultural,</b><br /><b>Transnational,</b><br />
                            <b>Transreligious</b><br />
& <b>Transracial</b>

</li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About