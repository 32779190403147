import React, { useState, useEffect } from 'react';

const Counter = ({ targetNumber, title }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const duration = 4000; // duration of the animation in ms
    const increment = targetNumber / (duration / 100);

    const animate = () => {
      start += increment;
      if (start < targetNumber) {
        setCount(Math.ceil(start));
        setTimeout(animate, 100);
      } else {
        setCount(targetNumber);
      }
    };

    animate();
  }, [targetNumber]);

  return (
    <div className="col-lg-3 col-md-6" data-aos="fade-up">
    <div className="counter-blk">
        
      {count}
      <span>{targetNumber !== 1.1 ? '+' : ''}</span>
    </div>{title}</div>
  );
};

export default Counter;
