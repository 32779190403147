import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
const Environmental = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Campus Amenities</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Campus Amenities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Campus </span> <span className="light-bg">Amenities</span></h3>
                            <div className="col-md-12">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <img src="https://webapi.entab.info/api/image/SGSM/public/Images/planet-earth.png" alt="St. George’s College" />
                                    </div>
                                    <p className="inner-title"><span>Green Campus</span></p>
                                    <p>St. George's College is committed to sustainability and environmental conservation. The campus features green spaces, gardens, and tree-lined avenues that create a refreshing and eco-friendly environment.</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Environmental