import axios from "axios"
import { API_URL, getHeader } from "../config"

export const getHighlight = async() => {
    const { data } = await axios.get(API_URL + `/api/highlight/SGSM`);
    console.log(data)
    return data;
} 
export const getBirthdays = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/SGSM`);
    return data;
} 
export const getBirthdaysAll = async() => {    
    const { data } = await axios.get(API_URL + `/api/birthdays/all/SGSM`);
    return data;
} 
export const getNews = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/SGSM`);
    console.log("news:",data)
    return data;
} 
export const getNewsInner = async() => {
    console.log("first")
    const { data } = await axios.get(API_URL + `/api/news/all/SGSM`);
    console.log("news:",data)
    return data;
} 

export const getEvents = async() => {
    const { data } = await axios.get(API_URL + `/api/events/SGSM`);
    console.log(data)
    return data;
} 

export const getEventsinner = async() => {
    const { data } = await axios.get(API_URL + `/api/events/All/SGSM`);
    console.log(data)
    return data;
} 
export const getWeeklyMenu = async() => {
    const { data } = await axios.get(API_URL + `/api/weeklyMenu/SGSM`);
    console.log(data)
    return data;
} 

export const getGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/SGSM`);
    return data;
} 

export const getGalleryInner = async() => {
    const { data } = await axios.get(API_URL + `/api/gallery/All/SGSM`);
    return data;
} 

export const getSubGallery = async(id) => {
    console.log(id)
    const { data } = await axios.get(API_URL + `/api/subGallery?id=${id}`);
    console.log(data)
    return data;
} 

export const getNotification = async() => {
    const { data } = await axios.get(API_URL + `/api/notification/SGSM`,getHeader());
    return data;
} 

export const getTopper = async() => {
    const { data } = await axios.get(API_URL + `/api/topper/SGSM`,getHeader());
    console.log("topper-data", data)
    return data;
} 

export const getOnlineRegistration = async() => {
    const { data } = await axios.get(API_URL + `/api/onlineRegistration/SGSM`,getHeader());
    console.log("online registration Data", data)
    return data;
} 

export const getSliders = async() => {
    const { data } = await axios.get(API_URL + `/api/slider/SGSM`,getHeader());
    return data;
}

export const getPrincipalMessage = async() => {
    const { data } = await axios.get(API_URL + `/api/principalMessage/SGSM`, getHeader());
    return data;
} 

export const getMedia = async() => {
    const { data } = await axios.get(API_URL + `/api/media/SGSM`, getHeader());
    console.log(data);
    return data;
} 
export const getVideoGallery = async() => {
    const { data } = await axios.get(API_URL + `/api/video/all/SGSM`, getHeader());
    return data;
} 
export const getCircular = async() => {
    const { data } = await axios.get(API_URL + `/api/circular/all/SGSM`, getHeader());
    console.log(data);
    return data;
} 
export const getReading = async() => {
    const { data } = await axios.get(API_URL + `/api/reading/all/SGSM`, getHeader());
    console.log(data);
    return data;
} 
export const getTestimonial = async() => {
    const { data } = await axios.get(API_URL + `/api/testimonials/SGSM`, getHeader());
    console.log(data);
    return data;
} 
export const getFaculty = async() => {
    const { data } = await axios.get(API_URL + `/api/faculty/all/SGSM`, getHeader());
    console.log(data);
    return data;
} 
export const getAcademicCalender = async() => {
    const { data } = await axios.get(API_URL + `/api/academicCalender/all/SGSM`, getHeader());
    console.log(data);
    return data;
} 

export const postTransferCertificate = async(name,admission) => {
    console.log(name,admission)

    const { data } = await axios.post(API_URL + `/api/tc/SGSM`,{name,admission},getHeader());
    return data;
}
