import React, { useEffect } from 'react';

const FacebookPagePlugin = () => {
    useEffect(() => {
        // Create a script element to load the Facebook SDK
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0';
        script.nonce = 'k5774A6o';
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="fb-root">
            <div 
                className="fb-page"
                data-href="https://www.facebook.com/StGeorgesCollegeOfficial"
                data-height="500"
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="false"
                data-show-posts="true"
                data-width="1000"
            >
                <blockquote
                    cite="https://www.facebook.com/StGeorgesCollegeOfficial"
                    className="fb-xfbml-parse-ignore"
                >
                    <a href="https://www.facebook.com/StGeorgesCollegeOfficial">St. George's College</a>
                </blockquote>
            </div>
        </div>
    );
};

export default FacebookPagePlugin;
