import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"
const campusAmenities = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Campus Amenities</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Campus Amenities</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Campus </span> <span className="light-bg">Amenities</span></h3>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <img src="https://webapi.entab.info/api/image/SGSM/public/Images/auditorium.png" alt="St. George’s College" />
                                    </div>
                                    <p className="inner-title"><span>Auditorium</span></p>
                                    <p>The college auditorium is a multi-purpose venue used for assemblies, cultural events, seminars, and guest lectures. It is equipped with modern sound and lighting systems, providing a professional setting for various events.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <img src="https://webapi.entab.info/api/image/SGSM/public/Images/chapel.png"  alt="St. George’s College" />
                                    </div>
                                    <p className="inner-title"><span>Chapel</span></p>
                                    <p>The college chapel offers a serene space for spiritual growth and reflection. Regular religious services and spiritual activities are conducted, fostering a sense of community and moral development among the students.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <img src="https://webapi.entab.info/api/image/SGSM/public/Images/wifi.png" alt="St. George’s College"  />
                                    </div>
                                    <p className="inner-title"><span>Wi-Fi Connectivity</span></p>
                                    <p>The entire campus is Wi-Fi enabled, providing students and faculty with access to the internet for educational and research purposes. This connectivity supports the integration of technology in the learning process.</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inner-facility">
                                    <div className="inner-facility_img">
                                        <img src="https://webapi.entab.info/api/image/SGSM/public/Images/music.png" alt="St. George’s College"  />
                                    </div>
                                    <p className="inner-title"><span>Modern Music and Dance Studio</span></p>
                                    <p>The School has a dedicated space for music and dance. It includes all modern instruments and sound systems enabling students to explore their creative talents and participate in various cultural activities.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default campusAmenities