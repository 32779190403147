 
import Header from "../component/Header"
import Footer from "../component/Footer" 
import { Link } from "react-router-dom" 

const EntryToIVVIII = () => {
return(
    <>
    <Header/> 

<div className="breadcrumb">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="d-flex">
                    <h2>ENTRY TO CLASS IV - VIII</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li> 
                        <li>|</li> 
                        <li>Admission</li> <li>|</li> 
                        <li>ENTRY TO CLASS IV - VIII</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div className="inner-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h3 className="inner-main-title"><span className="dark-bg">ENTRY TO </span> <span className="light-bg"> CLASS IV - VIII</span></h3>
                <p>St. George’s College has a demanding academic environment. Although the work is challenging, you will enjoy the opportunities to work closely with the teachers.</p>
                <p>The academic year commences in <b>February </b>and ends in <b>December </b>with the midterm break in <b>June</b>. The year is divided into three terms.</p>
                </div>
              
                <div className="col-md-12">
                    <p className="inner-title"> <span>SCHOOL DEPARTMENT</span></p>
                    <p className="inner-title"> <span>CLASS IV TO CLASS X</span></p>
                    <p><b>Indian Certificate of Secondary Education ( ICSE )</b></p>
                    <p>Teachers at St. George’s spend time working with students as individuals. It has a <b>1 :12 </b>teacher to students ratio. Up to Class VIII you have the following compulsory subjects with no options.</p>
                   <ul className="flex-list list-view">
                    <li>ENGLISH LANGUAGE</li>
                    <li>HISTORY & CIVICS</li>
                    <li>BIOLOGY</li>
                    <li>ENGLISH LITERATURE</li>
                    <li>GEOGRAPHY</li>
                    <li>ARTS</li>
                    <li>HINDI</li>
                    <li>MATHEMATICS</li>
                    <li>MUSIC</li>
                    <li>SANSKRIT | FRENCH | NEPALI</li>
                    <li>CHEMISTRY</li>
                    <li>VALUE EDUCATION</li>
                    <li>PHYSICS</li>
                   </ul>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
)
}
export default EntryToIVVIII