import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const MissionVision = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>VISION & MISSION</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Vision & Mission</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className="inner-main-title"><span className="dark-bg">Vision & </span> <span className="light-bg">Mission</span></h3>
                        </div>
                        <div className='col-md-6'>
                            <div className='vision'>
                                <img src="https://webapi.entab.info/api/image/SGSM/public/Images/vision.png" alt='st george college' />
                                <h3>Our Vision Statement</h3>
                                <p>To translate the school motto, 'Virtus et Labor' and develop young men of character, with active and creative minds, with a sense of commitment and compassion for others and the courage to act on their beliefs. We stress the holistic development of each child: spiritual, moral, intellectual, social, emotional and physical.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='vision'>
                                <img src="https://webapi.entab.info/api/image/SGSM/public/Images/mission.png" alt='st george college' />
                                <h3>Our Mission Statement</h3>
                                <p>Our mission is to promote lifelong learning in an open and caring atmosphere that motivates students to be confident and responsible global citizens. We strive to create a home away from home where universal brotherhood is imbibed and nurtured for a lifetime.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default MissionVision