import React from 'react';
import Slider from 'react-slick'; 

const Partners = () => {
    const settings = {
        infinite: true,
        speed: 9000, // Adjust the speed for smoother scrolling
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Adjust if you want to slow down or speed up
        cssEase: 'linear',
        draggable: false, // Optional: Disable dragging to ensure smooth scrolling
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className="gallery-box">
            <Slider {...settings} className="marquee-slider">
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/abacus.png" alt="Partner 1" /></div>
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/allen.png" alt="Partner 2" /></div>
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/cl.png" alt="Partner 3" /></div>
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/embibe.png" alt="Partner 4" /></div>
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/extramark.png" alt="Partner 5" /></div>
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/pw.png" alt="Partner 6" /></div> 
                <div className="content"><img src="https://webapi.entab.info/api/image/SGSM/public/Images/imageminds.jpg" alt="Partner 7" /></div> 
            </Slider>
        </div>
    );
}

export default Partners;
