import React, { useState, useEffect } from "react";
import Header from '../component/Header';
import Footer from '../component/Footer';
import { Link } from 'react-router-dom';
import { getWeeklyMenu } from '../Service/Api';

const WeeklyMenu = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [availableYears, setAvailableYears] = useState([]);
    const [availableMonths, setAvailableMonths] = useState([]);

    useEffect(() => {
        const fetchWeeklyMenuData = async () => {
            const weeklyMenuValue = await getWeeklyMenu();
            setData(weeklyMenuValue);
        };

        fetchWeeklyMenuData();
    }, []);

    useEffect(() => {
        const uniqueYears = [...new Set(data.map(item => new Date(item.date).getFullYear()))];
        setAvailableYears(uniqueYears);

        if (uniqueYears.includes(year)) {
            const uniqueMonths = [...new Set(data
                .filter(item => new Date(item.date).getFullYear() === year)
                .map(item => new Date(item.date).getMonth() + 1))];
            setAvailableMonths(uniqueMonths);
        }
    }, [data, year]);

    useEffect(() => {
        if (data.length > 0) {
            const filtered = data.filter(item => {
                const itemDate = new Date(item.date);
                return itemDate.getFullYear() === year && (itemDate.getMonth() + 1) === month;
            });
            setFilteredData(filtered);
        }
    }, [data, year, month]);

    const handleYearChange = (e) => {
        setYear(parseInt(e.target.value));
    };

    const handleMonthChange = (e) => {
        setMonth(parseInt(e.target.value));
    };

    const getWeekDay = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { weekday: 'long' });
    };

    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Weekly Food Menu</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Weekly Food  Menu</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Weekly</span> <span className="light-bg"> Food Menu</span></h3>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                            <div className="month-selection">
                                <select className="yearSelection" value={year} onChange={handleYearChange}>
                                    {availableYears.map((yearOption) => (
                                        <option key={yearOption} value={yearOption}>
                                            {yearOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-12">
                            <div className="month-selection">
                                <select className="monthSelect" value={month} onChange={handleMonthChange}>
                                    {availableMonths.map((monthOption) => (
                                        <option key={monthOption} value={monthOption}>
                                            {new Date(0, monthOption - 1).toLocaleString('default', { month: 'short' })}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Day</th>
                                            <th>Breakfast</th>
                                            <th>Lunch</th>
                                            <th>Dinner</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.length > 0 ? (
                                            filteredData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{getWeekDay(item.date)}</td>
                                                    <td>{item.breakfast}</td>
                                                    <td>{item.lunch}</td>
                                                    <td>{item.dinner}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default WeeklyMenu;
