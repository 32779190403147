import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const Philosophy = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Our Philosophy</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Our Philosophy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                        <h3 className="inner-main-title"><span className="dark-bg">Our </span> <span className="light-bg">Philosophy</span></h3>
                        </div> 
                        <div className='col-md-12'>                       
                        <p>The school is non-partisan, non-denominational and non-discriminatory. The only criteria for admission are academic merit and the willingness to do one's best to achieve excellence. Suitability of a candidate for the boarding will be evaluated before granting admission.</p>
                        <p>We believe that all the students once admitted become members of the manorite family, regardless of social and economic standing, and we owe them a duty to provide the highest possible standard of education.</p>
                        <p>The philosophy of the school embraces internationalism in its widest sense. Education is a global phenomenon; as such, we seek excellence wherever it is to be found and use it to raise our standards.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Philosophy