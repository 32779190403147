import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"

const Academic = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Academic Facilities </h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Facilities</li>
                                    <li>|</li>
                                    <li>Academic Facilities </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="inner-main-title"><span className="dark-bg">Academic</span> <span className="light-bg"> Facilities</span></h3>
                            <p>St. George's College, Mussoorie, has an extensive and well-maintained infrastructure spread across a 400 acres campus that supports its commitment to providing a holistic and comprehensive education. The college’s facilities are designed to cater to the academic, extracurricular, and residential needs of its students, ensuring an enriching and balanced school experience.</p>

                        </div>
                        <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/classroom.png" alt="St. George’s College"  />
                                </div>
                                <p className="inner-title"><span>Classrooms</span></p>
                                <p>The college features state-of-the-art classrooms equipped with modern teaching aids, including smart boards, projectors, and audio-visual equipment. This technology integration facilitates interactive and engaging learning experiences.</p> </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/laboratory.png" alt="St. George’s College"  />
                                </div>
                                <p className="inner-title"><span>Science Laboratories</span></p>
                                <p>St. George's College has fully equipped laboratories for Physics, Chemistry, and Biology. These labs provide students with hands-on learning opportunities and practical exposure to scientific concepts, fostering a deep understanding of the subject matter.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/lab.png" alt="St. George’s College"  />
                                </div>

                                <p className="inner-title"><span>Computer Labs</span></p>
                                <p>The school has multiple computer labs including a MAC Lab with the latest hardware and software to support the ICT curriculum. These labs enable students to acquire essential digital skills and stay updated with technological advancements.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="inner-facility">
                                <div className="inner-facility_img">
                                    <img src="https://webapi.entab.info/api/image/SGSM/public/Images/book.png" alt="St. George’s College"  />
                                </div>
                                <p className="inner-title"><span>Library</span></p>
                                <p>The well-stocked S.J Darcy library houses a vast collection of books, journals, and digital resources. It serves as a hub for research and independent study, encouraging students to develop a love for reading and learning.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Academic