import React, { useState } from 'react';
import  { Link } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function PrincipalMessage() {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const images = [ 
    { thumbnailUrl: 'principal2.png', largeImageUrl: 'principal2.png' }, 
    // Add more image objects as needed
  ];

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <div className="thumbnail-carousel-container">
      <div className="thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.thumbnailUrl}
            alt={`Thumbnail ${index}`}
            className={index === selectedImageIndex ? 'active' : ''}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
      <div className="main-carousel">
        <Carousel selectedItem={selectedImageIndex} onChange={setSelectedImageIndex}>
          {images.map((image, index) => (
            <div key={index}>
             <h3>Brother Ramesh</h3>
             <p>Principal</p>
             <p>“Education is not the filling of a pail but the lighting of a fire.” –William Butler Yeats.</p>
             <p>Voiced centuries ago, W.B. Yeats could not have spoken a truer word. Today we live in a changed world that is extremely competitive, radically developed and astonishingly dynamic. Education in itself has undergone a sea-change with the classroom becoming a platform for global ideas. Personalized learning, an increased focus on mental health, the growing importance of online classes and the impact of artificial intelligence all serve to underline the idea that education has come a long way from our traditional gurukul system.  However, the primary idea that education is a tool to draw out the intrinsic skills that lie within an  individual, still holds good.</p>
             <p> Today, the start-up culture owes its roots to the modern classroom and more than ever, the community of educators the world over must continually strive to keep abreast of emerging trends not just in terms of teaching but also in contributing to the global village. </p>
             <Link to='/principal-message'><button>READ MORE</button></Link>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default PrincipalMessage;
