import React, { useState } from 'react';
const Journey = () => {
  const [selectedTab, setSelectedTab] = useState('tab1');
  const handleTabClick = (tab) => {
    // document.querySelector(".tab-content > div").add.classList= "active";
    setSelectedTab(tab);
  };
  return (
    <div>
      <div className="tabs">
        <button className={selectedTab === 'tab1' ? 'active' : ''} onClick={() => handleTabClick('tab1')}> 1853</button>
        <button className={selectedTab === 'tab2' ? 'active' : ''} onClick={() => handleTabClick('tab2')}>1863</button>
        <button className={selectedTab === 'tab3' ? 'active' : ''} onClick={() => handleTabClick('tab3')}>1894</button>
        <button className={selectedTab === 'tab4' ? 'active' : ''} onClick={() => handleTabClick('tab4')}>1900</button>
        <button className={selectedTab === 'tab5' ? 'active' : ''} onClick={() => handleTabClick('tab5')}>1903</button>
        {/* <button className={selectedTab === 'tab6' ? 'active' : ''} onClick={() => handleTabClick('tab6')}>2000</button>
        <button className={selectedTab === 'tab7' ? 'active' : ''} onClick={() => handleTabClick('tab7')}>2011</button>
        <button className={selectedTab === 'tab8' ? 'active' : ''} onClick={() => handleTabClick('tab8')}>2019</button> */}
      </div>
      <div className="tab-content animateMe" data-animation="fadeIn">
        {selectedTab === 'tab1' && <div className="active">

        <div className='right-img-blk  animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey1.png" className='right-img' alt="St. George's College"  /></div>
        <h3>Sowing The Seeds (1853)</h3>
        <p className='animateMe' data-aos="fade-right">Stepping back in time to January 1853, Mr. Hutton sold the Manor House to Mr. Carli, who envisioned transforming it into a school. This initiative was likely driven by a perceived need among British Catholic parents for quality educational institutions that could provide their sons with an education comparable to the esteemed English Public School System. St. George's College embraced this mission, boasting a teaching staff trained in this very system. 
</p>
        <p className='animateMe' data-aos="fade-right">In a progressive move, the school welcomed students of faiths other than Roman Catholicism from the outset. Initially run by the Capuchin Friars, St. George's College saw its first Principal, Reverend Father Barry, welcome the inaugural batch of students in the same year. Thus, the tradition of excellence in education at St. George's College began in British-era Mussoorie.
</p>
              
            </div>
        }
        {selectedTab === 'tab2' && <div className="active">
        <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey2.png" className='right-img' alt="St. George's College"  /></div>
        <h3>A Fabled Rivalry (1863)</h3>
        <p className='animateMe' data-aos="fade-right">In 1863, just ten years after the establishment of St. George's College, a new institution emerged to accommodate 29 pupils transferred from the Catholic Orphan Asylum in Shimla. This was St. Fidelis' School. From its inception, Father Macken served as Principal for both St. Fidelis' and St. George's until 1868, playing a crucial role in the construction of many dormitories and classrooms.</p>
        <p className='animateMe' data-aos="fade-right">Over the years, a fabled rivalry developed between the two schools, a legacy fondly remembered to this day. In 1873, Father James Doogan arrived at Manor House, bringing his remarkable leadership and capabilities to the role of Principal for both schools. His 20-year tenure marked a long and eventful period in the history of these institutions, during which many significant changes and developments took place.
</p>
              
            </div>}
        {selectedTab === 'tab3' && <div className="active">
        <div className='right-img-blk  animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey3.png" className='right-img' alt="St. George's College"  /></div>
        <h3>A Change of Hands (1894)</h3>
        <p className='animateMe' data-aos="fade-right">As both institutions grew phenomenally and missionary work increased, the rapid expansion of the campus led to a severe understaffing due to a shortage of priests. This resulted in a landmark transfer of the establishments from the capable hands of the Capuchin Friars to the Patrician Brothers, a fraternity dedicated to the education of youth. Following the transfer, Brother Stapleton served as the Principal of both schools for a year. In 1894, Brother Byrne assumed leadership of St. Fidelis' School, while Brother Stapleton continued to steer St. George's College.
</p>
            </div>}
        {selectedTab === 'tab4' && <div className="active">
        <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey4.png" className='right-img' alt="St. George's College" /></div>
        <h3>An Ambitious Endeavour (1900)</h3>

            <p className='animateMe' data-aos="fade-right">In 1900, the leadership of St. George's College changed hands once again, this time to Brother Haverty, a distinguished thinker. Brother Haverty is credited with one of the most significant and ambitious developments of the early school years: leveling the hilltop to construct the 'Top Flat,' the largest and highest playground at the school, which remains the largest of its kind in Mussoorie.
</p>
            <p className='animateMe' data-aos="fade-right">During this period, the general architecture of the buildings was being refined for more purposeful use. The magnificent façade, which contributes to the school's renowned charm, was a notable development attributed to Brother Phelan. He enhanced the school compound by adding more buildings to accommodate the growing numbers of staff and students. A wise man and proficient architect, Brother Phelan also introduced the prudent idea of building embankments to protect the buildings and roads from landslides in the hills.
</p>
              
            </div>}
            {selectedTab === 'tab5' && <div className="active">
            <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey5.png" className='right-img' alt="St. George's College" /></div>
            <h3>Transformative Years</h3>
            <p className='animateMe' data-aos="fade-right">At the turn of the century, St. George's College underwent significant changes. In 1903, the old dormitory was replaced with a two-story building, and new classrooms and a playground were constructed. Electricity was installed in 1913, and a Hot Water Facility was added in 1916. Science labs were built in 1925, and the first motorable road to Dehradun was completed in 1927, easing transport and communication.
</p>
            <p className='animateMe' data-aos="fade-right">Academically, the school excelled, with notable achievements like A. Evans securing a First Division in the 1930 Inter Science Examination. The school's motto, *Virtus et Labor* (Virtue and Labour), was evident as students helped build the Top Flat in 1900 and supported the school during the Partition.
</p>
<p className='animateMe' data-aos="fade-right">Post-independence, staffing shortages and withdrawals led to the merger of St. Fidelis and St. George's into a single institution under the name St. George's College in 1948.
</p>
              
            </div>}
            {/* {selectedTab === 'tab6' && <div className="active">
            <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey6.png" className='right-img' />
            </div>
            <p className='animateMe' data-aos="fade-right">Nestled in the lap of heaven – St. George’s College – established in the year 1853 under the auspice of the OFM Fathers, has been a temple of learning for many a Manorite. Manor House, as the college is most popularly known, began in an attempt to provide quality education to British Catholic children, on the lines of the Public-School System – the Cambridge board in Britain. The school was initially administered by the Capuchin Fathers but was later handed over to the Society of the Reverend Brothers of St. Patrick (Ireland) in 1894 with Bro. Stapleton as its first principal. Ever since the school has grown from strength to strength.</p>
            <p className='animateMe' data-aos="fade-right">While St. George’s College was founded in 1853, another school, St. Fidelis High School, was started in the same location in 1863. Initially, the Capuchin Fathers ran both schools with Father James Doogan as principal of both schools from 1873 till they were handed over to the Patrician Brothers in 1894. In 1948, St. Fidelis High School was amalgamated with St. George’s College. There still are old directional markings on the school campus pointing towards the old St. Fidelis. </p>
              
            </div>}
            {selectedTab === 'tab7' && <div className="active">
            <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey7.png" className='right-img' />
            </div>
            <p className='animateMe' data-aos="fade-right">Nestled in the lap of heaven – St. George’s College – established in the year 1853 under the auspice of the OFM Fathers, has been a temple of learning for many a Manorite. Manor House, as the college is most popularly known, began in an attempt to provide quality education to British Catholic children, on the lines of the Public-School System – the Cambridge board in Britain. The school was initially administered by the Capuchin Fathers but was later handed over to the Society of the Reverend Brothers of St. Patrick (Ireland) in 1894 with Bro. Stapleton as its first principal. Ever since the school has grown from strength to strength.</p>
            <p className='animateMe' data-aos="fade-right">While St. George’s College was founded in 1853, another school, St. Fidelis High School, was started in the same location in 1863. Initially, the Capuchin Fathers ran both schools with Father James Doogan as principal of both schools from 1873 till they were handed over to the Patrician Brothers in 1894. In 1948, St. Fidelis High School was amalgamated with St. George’s College. There still are old directional markings on the school campus pointing towards the old St. Fidelis. </p>
              
            </div>} 
            {selectedTab === 'tab8' && <div className="active">
            <div className='right-img-blk animateMe' data-aos="fade-left"> <img src="https://webapi.entab.info/api/image/SGSM/public/Images/journey8.png" className='right-img' /></div>
            <p className='animateMe' data-aos="fade-right">Nestled in the lap of heaven – St. George’s College – established in the year 1853 under the auspice of the OFM Fathers, has been a temple of learning for many a Manorite. Manor House, as the college is most popularly known, began in an attempt to provide quality education to British Catholic children, on the lines of the Public-School System – the Cambridge board in Britain. The school was initially administered by the Capuchin Fathers but was later handed over to the Society of the Reverend Brothers of St. Patrick (Ireland) in 1894 with Bro. Stapleton as its first principal. Ever since the school has grown from strength to strength.</p>
            <p className='animateMe' data-aos="fade-right">While St. George’s College was founded in 1853, another school, St. Fidelis High School, was started in the same location in 1863. Initially, the Capuchin Fathers ran both schools with Father James Doogan as principal of both schools from 1873 till they were handed over to the Patrician Brothers in 1894. In 1948, St. Fidelis High School was amalgamated with St. George’s College. There still are old directional markings on the school campus pointing towards the old St. Fidelis. </p>
              
            </div>} */}
      </div>
    </div>
  );
};
export default Journey;