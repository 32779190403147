import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'
const SchoolSong = () => {
    return (
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>School Song</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>School Song</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className="inner-main-title"><span className="dark-bg">School </span> <span className="light-bg">Song</span></h3>
                        </div>
                        <div className='col-md-12'>
                            <div className='school_song'>
                                <p className="inner-title">Written by Mrs. Ince (circa 1980) to the music of Dauntless Ignatius by Rev. C.W. Smith</p>
                                <img src="https://webapi.entab.info/api/image/SGSM/public/Images/line.png" alt="St. George’s College"  />
                                <p><span>Chorus.</span> Home of our Childhood, our boyhood, our youth, Where there is courage and honour and truth, Where we are taught how to seek for the right, Struggle and conquer in life's great fight.</p>
                                <p><span>Verse 1</span> Virtue and labour. Our motto we'll strive Ever to follow, and keep alive Ideals and aims that it teaches us here, True to St. George, our Patron dear.</p>
                                <p><span>Chorus.</span> Home of our Childhood, our boyhood, our youth, Where there is courage and honour and truth, Where we are taught how to seek for the right, Struggle and conquer in life's great fight.</p>
                                <p><span>Verse 2.</span> God bless St. George's! Let this be our prayer That He always have our school in His care. Ever our banner we'll keep flying high, For God and the truth we'll live or we'll die,</p>
                                <p><span>Chorus.</span> Home of our Childhood, our boyhood, our youth, Where there is courage and honour and truth, Where we are taught how to seek for the right, Struggle and conquer in life's great fight.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default SchoolSong