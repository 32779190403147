import Header from "../component/Header"
import Footer from "../component/Footer"
import { Link } from "react-router-dom"

const Timeline= () => {
    return(
        <>
            <Header />
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Timeline</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>About</li>
                                    <li>|</li>
                                    <li>Timeline</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">                            
                            <h3 className="inner-main-title"><span className="dark-bg">Timeline</span>  </h3>
                             {/* <p className="inner-title"><span>Boarding Houses</span></p> */}
                             <div class="timeline">
                                <div className="d-flex">
                                    <div><p className="inner-title"><span>Sowing The Seeds (1853)</span></p></div>
                                    <div className="content1">
                                        <p>Stepping back in time to January <span>1853,</span> Mr. Hutton sold the Manor House to Mr. Carli, who envisioned transforming it into a school. This initiative was likely driven by a perceived need among British Catholic parents for quality educational institutions that could provide their sons with an education comparable to the esteemed English Public School System. St. George's College embraced this mission, boasting a teaching staff trained in this very system. </p>
                                        <p>In a progressive move, the school welcomed students of faiths other than Roman Catholicism from the outset. Initially run by the Capuchin Friars, St. George's College saw its first Principal, Reverend Father Barry, welcome the inaugural batch of students in the same year. Thus, the tradition of excellence in education at St. George's College began in British-era Mussoorie.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div><p className="inner-title"><span>A Fabled Rivalry (1863)</span></p></div>
                                    <div className="content1">
                                        <p>In <span>1863,</span> just ten years after the establishment of St. George's College, a new institution emerged to accommodate 29 pupils transferred from the Catholic Orphan Asylum in Shimla. This was St. Fidelis' School. From its inception, Father Macken served as Principal for both St. Fidelis' and St. George's until 1868, playing a crucial role in the construction of many dormitories and classrooms.</p>
                                        <p>Over the years, a fabled rivalry developed between the two schools, a legacy fondly remembered to this day. In 1873, Father James Doogan arrived at Manor House, bringing his remarkable leadership and capabilities to the role of Principal for both schools. His 20-year tenure marked a long and eventful period in the history of these institutions, during which many significant changes and developments took place.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div><p className="inner-title"><span>A Change of Hands (1894)</span></p></div>
                                    <div className="content1">
                                        <p>As both institutions grew phenomenally and missionary work increased, the rapid expansion of the campus led to a severe understaffing due to a shortage of priests. This resulted in a landmark transfer of the establishments from the capable hands of the Capuchin Friars to the Patrician Brothers, a fraternity dedicated to the education of youth. Following the transfer, Brother Stapleton served as the Principal of both schools for a year. In 1894, Brother Byrne assumed leadership of St. Fidelis' School, while Brother Stapleton continued to steer St. George's College.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div><p className="inner-title"><span>An Ambitious Endeavour (1900)</span></p></div>
                                    <div className="content1">
                                        <p>In <span>1900,</span> the leadership of St. George's College changed hands once again, this time to Brother Haverty, a distinguished thinker. Brother Haverty is credited with one of the most significant and ambitious developments of the early school years: leveling the hilltop to construct the 'Top Flat,' the largest and highest playground at the school, which remains the largest of its kind in Mussoorie.</p>
                                        <p>During this period, the general architecture of the buildings was being refined for more purposeful use. The magnificent façade, which contributes to the school's renowned charm, was a notable development attributed to Brother Phelan. He enhanced the school compound by adding more buildings to accommodate the growing numbers of staff and students. A wise man and proficient architect, Brother Phelan also introduced the prudent idea of building embankments to protect the buildings and roads from landslides in the hills.
</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div><p className="inner-title"><span>Transformative Years</span></p></div>
                                    <div className="content1">
                                        <p>At the turn of the century, St. George's College underwent significant changes. In <span>1903,</span> the old dormitory was replaced with a two-story building, and new classrooms and a playground were constructed. Electricity was installed in 1913, and a Hot Water Facility was added in 1916. Science labs were built in 1925, and the first motorable road to Dehradun was completed in 1927, easing transport and communication.</p>
                                        <p>Academically, the school excelled, with notable achievements like A. Evans securing a First Division in the 1930 Inter Science Examination. The school's motto, *Virtus et Labor* (Virtue and Labour), was evident as students helped build the Top Flat in 1900 and supported the school during the Partition.</p>
                                        <p>Post-independence, staffing shortages and withdrawals led to the merger of St. Fidelis and St. George's into a single institution under the name St. George's College in 1948.</p>
                                    </div>
                                </div>
                             </div>
                             
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Timeline