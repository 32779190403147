 
import Header from "../component/Header"
import Footer from "../component/Footer" 
import { Link } from "react-router-dom" 

const EntryToSgc = () => {
return(
    <>
    <Header/> 

<div className="breadcrumb">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="d-flex">
                    <h2>Entry To Sgc</h2>
                    <ul>
                        <li><Link to="/">Home</Link></li> 
                        <li>|</li> 
                        <li>Admission</li> <li>|</li> 
                        <li>Entry To Sgc</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    <div className="inner-section">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                <h3 className="inner-main-title"><span className="dark-bg">REGISTRATION</span> <span className="light-bg"> PROCESS</span></h3>
                <p>Admissions are open for the year 2025 ( Class IV – IX ) . You can register online from this website.</p>
                <p><b><i class="bi bi-telephone"></i></b> <a href="tel:7060311770">7060311770</a> | <a href="tel:7060311771">7060311771</a><br />
<b><i class="bi bi-envelope"></i></b> <a href="mailto:principal@sgconline.ac.in">principal@sgconline.ac.in</a></p>
<p>Entrance test for Class IV – IX will be held on Saturday, <b>5th October 2024</b>, at SGC, Mussoorie (guidelines of which will be sent through email a few days prior to the test).</p>

                </div>
                <div className="col-md-12 note">
                    <p className="inner-title"><span>PLEASE NOTE</span></p>
                    <ul className="list-view">
                        <li>The School accepts <b>NO Donations</b> or Recommendations for Admission.</li>
                        <li>Use of any influence or unfair means to secure admission will lead to automatic Disqualification.</li>
                        <li>The School has NO outside Agency for Admissions.</li>
                        <li>The School reserves its right to Admit or Deny admissions to any candidate.</li>
                    </ul>
                </div>
                <div className="col-md-12">
                    <p className="inner-title"> <span>REGISTRATION</span></p>
                    <p>Admission is offered to students of all communities, without distinction of caste, creed, colour or religion in St. George’s College. Registration entitles a student to appear for the Entrance Test and does not guarantee admission. Admissions are finalised on the basis of the child’s performance in the Entrance Test.</p>
                    <p>The following documents are required for registration process: A Demand Draft for <b> Rs. 10,000/- (Non–refundable)</b> in favour of St. George’s College, Mussoorie, payable at Mussoorie, three recent passport size photographs, Birth Certificate, latest progress report and copy of Aadhaar card of the student which is mandatory.</p>
                    <p>You can also RTGS the same – ST. GEORGE’S COLLEGE MUSSOORIE,<br></br> <span>STATE BANK OF INDIA, ACCOUNT NO:</span> 10437445699,<br>
                    </br> <span>IFSC CODE:</span> SBIN0010587,<br></br> <span>BRANCH CODE:</span> 10587</p>
                    <p><b>FOR ANY QUERIES CONTACT:</b></p>
                    <p><i class="bi bi-envelope"></i> principal@sgconline.ac.in, secretary@sgconline.ac.in</p>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
)
}
export default EntryToSgc