import React, { useEffect, useState } from "react";
import { getEventsinner } from "../Service/Api";
import Header from '../component/Header' 
import { Link } from 'react-router-dom';
import Footer from "../component/Footer";

const Events = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState();
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const Year = `${currentYear}`;
        setSelectedYear(Year);
        console.log("get current Year", Year);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const galleryData = await getEventsinner(); 
            setData(galleryData);

            const uniqueYears = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const year = new Date(item.date).getFullYear();
                        return [year];
                    } else {
                        return [];
                    }
                }))
            );
            const uniqueYearRanges = uniqueYears.map(year => `${year}`);
            uniqueYearRanges.sort((a, b) => b - a);
            setYearRanges(uniqueYearRanges);

            const uniqueMonths = Array.from(
                new Set(galleryData.flatMap((item) => {
                    if (item.date) {
                        const month = new Date(item.date).getMonth();
                        return [month];
                    } else {
                        return [];
                    }
                }))
            );
            const monthOptions = uniqueMonths.sort((a, b) => a - b).map(month => monthNames[month]);
            setMonthOptions(["All", ...monthOptions]);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let filteredData = data;
        if (selectedYear !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const year = new Date(item.date).getFullYear();
                    const month = new Date(item.date).getMonth();
                    return parseInt(selectedYear) === year && (selectedMonth === "All" || monthNames[month] === selectedMonth);
                } else {
                    return false;
                }
            });
        }
        if (selectedMonth !== "All") {
            filteredData = filteredData.filter((item) => {
                if (item.date && item.date.trim() !== "") {
                    const month = new Date(item.date).getMonth();
                    return monthNames[month] === selectedMonth;
                } else {
                    return false;
                }
            });
        }
        if (searchQuery) {
            filteredData = filteredData.filter((item) =>
                item.title.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        // Sort the filtered data by date in descending order
      //   filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);

    return (
        <>
            <Header />
      <div className="breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <h2>Events </h2>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li>|</li>
                  <li>Events </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="button-list-block">
                            <Link to="/Events" className="active">  <button>Events</button></Link>
                               <Link to="/News"> <button>News</button></Link>
                            </div>
                        </div>
                    </div>
                    <div className="row tabs-dynamic">
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="count-val">
                                <p>Total Count: {filterData.length} </p>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select  
                                    value={selectedYear}
                                    onChange={(e) => setSelectedYear(e.target.value)}
                                    className="yearSelection">
                                    <option value={"All"}>All</option>
                                    
                                    {yearRanges.map((yr) => (
                                        <option key={yr} value={yr}>{yr}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="month-selection">
                                <select
                                    value={selectedMonth}
                                    onChange={(e) => setSelectedMonth(e.target.value)}
                                    className="monthSelect"> 
                                    {monthOptions.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                            <div className="searchBox">
                                <input
                                    type="text"
                                    id="myInput"
                                    name="name"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder="Search Here.."
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row years year2023 showYear">
                        {filterData.length > 0 &&
                            filterData.map((item, index) => (
                                <div className="col-md-12 eventCount" key={index}>
                                    <div className="event-blk">
                                       
                                            <div className="inr-event-blk">
                                                <div className="inr-event-img">
                                                    <img src={`https://webapi.entab.info/api/image/${item?.images}`} className="img-fluid mx-auto d-block" alt="St George college musoori" />
                                                </div>
                                                <div className="inr-event-content">
                                                    <h6 className="date">{item.date}</h6>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.description}</p>
                                                    <h6 className="loc">
                                                        <i className="bi bi-geo-alt"></i>{item.venue} <span><i className="bi bi-alarm"></i> {item.time}</span>
                                                        {item.attachments && item.attachments.length > 0 && (<Link to={`https://webapi.entab.info/api/image/${item?.attachments}`} target="_blank"> <span> View Attachment <i className="bi bi-arrow-right"></i> </span></Link>)}
                                                    </h6>
                                                </div>
                                            </div>
                                        
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Events;
